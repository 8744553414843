<template>
  <div class="admin">
    <div class="admin-area">

      <div class="row row-gutter-20">
        <div class="col-6">
          <admin-nav></admin-nav>
        </div>
        <div class="col-18">

          <header class="admin-header cf">
            <ul class="nav nav-header cf">
              <li>Buchungskalender</li>
            </ul>
            <div class="header-actions">

            </div>
          </header>

          <div class="admin-body">

            <div class="calendar-vehicles">

              <div class="row row-gutter-20">
                <div class="col-12">
                  <div class="form-wrap select-wrap">
                    <label for="selected_month">Monat auswählen</label>
                    <select v-model="selected_month" id="selected_month">
                      <option v-for="month in available_months" v-bind:key="month.id" v-bind:value="month.id">{{ month.name }}</option>
                    </select>
                  </div>
                </div>
                <div class="col-12">
                  <div class="form-wrap select-wrap">
                    <label for="selected_year">Jahr auswählen</label>
                    <select v-model="selected_year" id="selected_year">
                      <option value="2020">2020</option>
                      <option value="2021">2021</option>
                      <option value="2022">2022</option>
                      <option value="2023">2023</option>
                      <option value="2024">2024</option>
                      <option value="2025">2025</option>
                    </select>
                  </div>
                </div>
              </div>

              <div v-if="loading">
                <div class="loading-wrap">
                  <div class="loading-spinner"></div>
                </div>
              </div>
              <div v-else>

                <div v-for="vehicle in vehicles" v-bind:key="vehicle.id" class="vehicle cf">
                  <div class="vehicle-head">
                    <h3>{{ vehicle.license_plate }} <small style="display:block;margin-top:5px;font-weight: 400">{{ vehicle.category.name }}</small><small v-if="vehicle.location" style="display:block;margin-top:5px;font-weight: 400">{{ vehicle.location.name }}</small></h3>
                  </div>
                  <div class="c-days" v-for="(single_day, idx) in vehicle.availability" v-bind:key="idx">
                    <router-link v-if="single_day.available" to="/admin/reservations/new" class="c-day" style="background:green;color: #fff">{{ single_day.day }}</router-link>
                    <span v-else class="c-day" style="background:red;color: #fff" @mouseover="day = single_day" v-popover:res-ov.top>{{ single_day.day }}</span>
                  </div>
                </div>

              </div>

            </div>

          </div>
        </div>
      </div>
    </div>

    <popover name="res-ov" event="click">
      <ul class="nav-reservations">
        <li v-for="res in day.reservation_array" v-bind:key="res">
          <router-link :to="'/admin/reservations/'+res.id">
            {{ res.pickup_date }} {{ res.pickup_time }} bis {{ res.return_date }} {{ res.return_time }} Uhr<br>
            <span v-if="res.company">{{ res.company }}</span><span v-else>{{ res.name }}</span>
          </router-link>
        </li>
      </ul>
    </popover>

  </div>
</template>

<script>
import axios from 'axios'
import AdminNav from '../../../components/admin_nav'
import moment from 'moment'

export default {
  name: 'admin_calendar',
  components: {
    AdminNav
  },
  data () {
    return {
      location: "",
      locations: [],
      vehicles_temp: [],
      vehicles: [],
      loading: false,
      days: [],
      day: {},
      available_months: [],
      available_years: [],
      selected_month: "",
      selected_year: "2021",
      available_counter: 0
    }
  },
  methods: {
    get_data() {

      axios.get(process.env.VUE_APP_BASE_API+'/v1/admin/locations', { headers: { Authorization: this.$store.getters.getToken }})
      .then(response => {
        console.log(response);
        this.locations = response.data.locations;
      })
      .catch(error => {
        console.log(error);
      })

    },
    get_vehicles(month) {

      axios.get(process.env.VUE_APP_BASE_API+'/v1/admin/vehicles', { headers: { Authorization: this.$store.getters.getToken }})
      .then(response => {
        this.vehicles_temp = response.data.vehicles;
        this.vehicles_temp.forEach(vehicle => {
          this.check_availability(vehicle, month);
        })
      })
      .catch(error => {
        console.log(error);
      })

    },
    check_availability(vehicle, month) {
      axios.get(process.env.VUE_APP_BASE_API+'/v1/admin/vehicles/'+vehicle.id+'/available?month='+month+'&year='+this.selected_year, { headers: { Authorization: this.$store.getters.getToken }})
      .then(response => {

        this.vehicles_temp.forEach(v => {

          if (vehicle.id == v.id) {

            v.availability = response.data.availability;
            var included = false;

            this.vehicles.forEach(ve => {
              if (ve.license_plate == v.license_plate) {
                included = false;
              }
            });
            if (included == false) {
              this.vehicles.push(v);
              this.check_counter();
            }
          }
        })
      })
    },
    check_counter() {
      if (this.vehicles_temp.length == this.vehicles.length) {
        this.vehicles.sort((a, b) => parseFloat(a.group_id) - parseFloat(b.group_id));
        this.loading = false;
      }
    },
    get_days() {

    },
    set_day(day) {
      this.day = day;
    }
  },
  mounted () {
    this.get_data();

    moment.updateLocale('en', {
      months : [
        "Januar", "Februar", "März", "April", "Mai", "Juni", "Juli", "August", "September", "Oktober", "November", "Dezember"
      ]
    });

    var times = 12;
    for(var i=0; i < times; i++){
      this.available_months.push({id: moment(new Date('January 15, 2021 03:24:00').setMonth(i)).format("M"), name: moment(new Date('January 15, 2021 03:24:00').setMonth(i)).format("MMMM")})
      this.available_years.push({id: moment(new Date('January 15, 2021 03:24:00').setMonth(i)).format("YYYY"), name: moment(new Date('January 15, 2021 03:24:00').setMonth(i)).format("YYYY")})
    }


    setTimeout(() => {
      this.selected_month = moment(new Date()).format("M");
    }, 200)
  },
  watch: {
    selected_month: function(val) {
      this.vehicles_temp = [];
      this.vehicles = [];
      this.loading = true;
      this.get_vehicles(val);
    },
    selected_year: function() {
      this.vehicles_temp = [];
      this.vehicles = [];
      this.loading = true;
      this.get_vehicles(this.selected_month);
    },
  }
}
</script>

<style lang="scss">

  .calendar-vehicles {

    .vehicle {
      padding: 20px 0;
      margin-bottom: 10px;

      .vehicle-head {
        float: left;
        width: 220px;
        padding-right: 20px;

        h3 {
          margin: 0;
          font-size: 16px;
          font-weight: 600;
        }
      }

      .vehicle-reservations {
        float: left;
        margin-top: 20px;
        border: 2px solid #f6f6f6;
        padding: 20px;
        width: calc(100% - 220px);

        h5 {
          margin: 0 0 14px 0;
          font-size: 15px;
          font-weight: 600;
        }
      }
    }
  }

  .c-days {
    margin-top: 2px;
    display: inline-block;
  }

  .c-day {
    margin: 0;
    display: inline-block;
    width: 25px;
    height: 25px;
    background: #f1f1f1;
    margin-right: 4px;
    margin-bottom: 2px;
    padding: 6px 0;
    text-align: center;
    font-size: 12px;
    font-weight: 600;
    position: relative;
    cursor: pointer;

    .reservation-overview {
      position: absolute;
      width: 200px;
      background: #f1f1f1;
      color: #444;
      z-index: 22;
      top: 50px;
      left: -100px;
      padding: 15px;

      ul {
        margin: 0;
        padding: 0;

        li {
          list-style-type: none;
        }
      }
    }

    .reservation-overview:hover {
      opacity: 1;
    }
  }

  .nav-reservations {
    padding: 0;
    margin: 0;

    li {
      list-style-type: none;
      margin-bottom: 5px;

      a {
        display: block;
        background: #f9f9f9;
        border: 1px solid #f1f1f1;
        padding: 5px;
        font-size: 14px;
        color: #444;
      }
    }

    li:last-child {
      margin-bottom: 0;
    }
  }

</style>
